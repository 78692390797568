<template>
    <div v-editable="blok" class="l4-page-title md:container" data-test="l4-page-title" :style="styles">
        <RichText :richtext="blok.text"></RichText>
    </div>
</template>

<script setup lang="ts">
import type { IL4PageTitleConfig } from '~/types/components/L4PageTitle';
import { useIsMobileView } from '~ui/composables/isMobileView';

const { blok } = defineProps<{ blok: IL4PageTitleConfig }>();

const { isMobileView } = useIsMobileView('768px');

const styles = computed(() => ({
    paddingTop: blok.paddingTop ? blok.paddingTop : null,
    paddingBottom: blok.paddingBottom ? blok.paddingBottom : null,
    fontSize: isMobileView.value
        ? blok.fontSizeMobile
            ? blok.fontSizeMobile
            : null
        : blok.fontSize
          ? blok.fontSize
          : null,
}));
</script>

<style scoped lang="postcss">
.l4-page-title {
    @apply px-4 md:px-0 font-bold text-bodypurple text-[2rem];
    line-height: 1;
    :deep(.rich-text) {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            @apply inline;
            font-size: inherit;
        }
    }
}
</style>
